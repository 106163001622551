<template>
  <section class="section" id="sectionPrincipal">
    <div class="columns is-desktop">
      <div class="column is-half-desktop is-offset-one-quarter">
        <h1 class="subtitle-about" style="">SOBRE</h1>
        <h1 class="title-about" style="">NOSOTROS</h1>
        <p style="color: white"><span class="text-bold">MIApp</span> es tu mejor opción financiera. El objetivo principal de <span class="text-bold">MIApp</span> es facilitar tus transacciones diarias, eliminando las largas filas y personalizando el servicio que recibes. Para lograr esto atacamos las áreas principales y más débiles de la competencia con una regla especifica: <span class="text-bold">no usar robots.</span> No usamos robots, cada transacción que hace el cliente hay un agente de <span class="text-bold">MIApp</span> pendiente por si necesitas ayuda.</p>
        <br>
        <p style="color: #ffffff">Nuestra propuesta de valor es clara y específica:</p>
        <p style="color: #ffffff">1. Atención personalizada.</p>
        <p style="color: #ffffff">2. Sistema de intereses único y rentable.</p>
      </div>
    </div>


    <div class="columns is-desktop">
      <div class="column is-offset-1">
        <h1 class="title-values">Nuestra Misión</h1>
        <p style="color: #ffffff">Ser tu sistema financiero favorito, facilitando toda transacción y siempre ofreciendo más.</p>
      </div>
      <div class="column">
        <h1 class="title-values">Nuestra Visión</h1>
        <p style="color: #ffffff">Ser la solución financiera más rápida y estable del mundo.</p>
      </div>
      <div class="column is-1"></div>
    </div>
  </section>
</template>

<script>
export default{
  name: 'AboutComponent',
  mounted() {
    this.asignHeight();
  },
  methods: {
    asignHeight(){
        let height = window.innerHeight;
        let element = document.getElementById("sectionPrincipal");
        element.style.height=height + "px";
    }
  }
}
</script>

<style>
#sectionPrincipal{
  background-image: url("../assets/images/fondoblack1.png");
}

.text-bold{
  font-weight: 700;
}

.title-about{
  color: white; 
  font-weight: 800; 
  font-size: 3rem;
  margin-top: -1.25rem;
}

.subtitle-about{
  color: white; 
  font-size: 1.8rem; 
  font-weight: 500;
}

.title-values{
  color: #286DC2; 
  font-weight: 800; 
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .title-about{
    font-size: 1.5rem;
    margin-top: -0.9rem;
  }

  .subtitle-about{
    font-size: 1.2rem;
  }

  .title-values{
    font-size: 1.2rem;
  }
}
</style>