import {createApp} from 'vue';
import {createRouter, createWebHashHistory} from 'vue-router';
import { createStore } from 'vuex';
import App from './App.vue';
import '../node_modules/bulma/css/bulma.css';
import { getAuth } from 'firebase/auth';
import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';

//Import components
import LoginComponent from './components/Login.vue'
import HelloWorld from './components/HelloWorld.vue'
import RegisterComponent from './components/Register.vue'
import AboutComponent from './components/About.vue'
import ProductsComponent from './components/Products.vue'
import ContactComponent from './components/Contact.vue'
import DashboardComponent from './components/Dashboard.vue'
import ProfileComponent from './components/Profile.vue'
import EditProfileComponent from './components/EditProfile.vue'
import DepositComponent from './components/Deposits.vue'
import TransferComponent from './components/Transfers.vue'
import WithdrawalComponent from './components/Withdrawal.vue'
import MyWithdrawalsComponent from "./components/MyWithdrawals";
import RemesasComponent from "./components/Remesas";
import MIRemesasComponent from "@/components/MIRemesas";

//Define routes
const routes = [
    {path: '/', component: HelloWorld},
    {path: '/login', component: LoginComponent},
    {path: '/register', component: RegisterComponent, name: 'Register'},
    {path: '/about', component: AboutComponent},
    {path: '/products', component: ProductsComponent},
    {path: '/contact', component: ContactComponent},
    {path: '/dashboard', component: DashboardComponent, name: 'Dashboard'},
    {path: '/my-profile', component: ProfileComponent, name: 'Profile'},
    {path: '/edit-profile', component: EditProfileComponent, name: 'EditProfile'},
    {path: '/deposit', component: DepositComponent, name: 'Deposit'},
    {path: '/transfer', component: TransferComponent, name: 'Transfer'},
    {path: '/withdrawal', component: WithdrawalComponent, name: 'Withdrawal'},
    {path: '/mywithdrawals', component: MyWithdrawalsComponent, name: 'MyWithdrawals'},
    {path: '/mipago', component: RemesasComponent, name: 'Remesas'},
    {path: '/miremesas', component: MIRemesasComponent, name: 'MIRemesas'}
]

const protectedRoutes = [
    'Dashboard',
    'Deposit',
    'Withdrawal',
    'TransferComponent',
    'EditProfile',
    'Profile',
    'MyWithdrawals',
    'Transfer'
]
//Create the router instance and pass the 'routes' option
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

//Create a new store instance
export const store = createStore({
    state(){
        return{
            balance: null,
            user: {
                loggedIn: false,
                data: null
            }
        }
    },
    getters:{
        user(state){
          return state.user;
        },
        loggedIn(state){
          return state.user.loggedIn;
        },
        getDisplayName(state){
            return state.user.data.displayName;
        },
        getEmail(state){
            return state.user.data.email;
        }
    },
    mutations: {
        setBalance(state, value){
            state.balance = value;
        },
        setLoggedIn(state, value){
            state.user.loggedIn = value;
        },
        setUser(state, data){
            state.user.data = data;
        }
    },
    actions: {
        fetchUser({commit}, user){
            if(user){
                commit('setUser', {
                    displayName: user.display_name,
                    email: user.email
                });
                if(user.email === 'modinves@modinves.com'){
                    commit('setLoggedIn', false);
                } else {
                    commit('setLoggedIn', true);
                }
            } else {
                commit('setUser', null);
                commit('setLoggedIn', false);
            }
        }
    }
});

//Guard for routes
router.beforeEach((to, from, next) => {
    const isProtected = protectedRoutes.includes(to.name);
    if(to.name === 'Register' && store.state.user.loggedIn){
        next({
            path: '/dashboard'
        })
    }

    if(isProtected && !store.state.user.loggedIn){
        next({
            path: '/login'
        })
    } else next()
})

//Create and mount the root instance
const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueSweetalert2);

getAuth().onAuthStateChanged(user => {
    store.dispatch('fetchUser', user);
});

app.config.globalProperties.$filters = {
    timestampToDate(value){
        let date = value.toDate();
        return date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear();
    }
}

app.mount('#app');