<template>
  <h1 class="title">Deposit</h1>
  <div class="section pt-4 pb-0">
    <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
      <ul class="container">
        <li id="deposit" class="is-active link"><a aria-current="step" @click="changeClass('deposit')">Deposit</a></li>
        <li id="card"><a @click="changeClass('card')">Card</a></li>
        <li id="address"><a @click="changeClass('address')">Billing Address</a></li>
        <li id="confirm" class=""><a @click="changeClass('confirm')">Confirm</a></li>
      </ul>
    </nav>
    <form action="" >
      <div class="container">
        <div class="columns">
          <div class="column"></div>
          <div class="column is-8">
            <div id="divDeposit" class="visible">
              <div class="field">
                <label for="" class="label">Amount:</label>
                <div class="control">
                  <input type="number" class="input" v-model="amount" placeholder="Amount to deposit" />
                </div>
              </div>
            </div>

            <div id="divCard" class="is-hidden">
              <div class="field">
                <label for="" class="label">Number card:</label>
                <div class="control">
                  <input type="text" class="input" v-model="numberCard" placeholder="Number card...">
                </div>
              </div>

              <div class="field">
                <div class="columns">
                  <div class="column">
                    <label for="" class="label">Month expire:</label>
                    <div class="control">
                      <select class="input" v-model="monthExpire" name="" id="month">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </div>
                  </div>
                  <div class="column">
                    <label for="" class="label">Year expire:</label>
                    <div class="control">
                      <select name="" class="input" v-model="yearExpire">
                        <option value="23">2023</option>
                        <option value="24">2024</option>
                        <option value="25">2025</option>
                        <option value="26">2026</option>
                        <option value="27">2027</option>
                        <option value="28">2028</option>
                        <option value="29">2029</option>
                        <option value="2030">2030</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <label for="" class="label">Cvv:</label>
                <div class="control">
                  <input type="password" class="input" v-model="cvv" placeholder="Your code cvv">
                </div>
              </div>

              <div class="field">
                <label for="" class="label">Card holder:</label>
                <div class="control">
                  <input type="text" class="input" v-model="cardHolder" placeholder="Card holder...">
                </div>
              </div>
            </div>

            <div id="divAddress" class="is-hidden">
              <div class="field">
                <label for="" class="label">Address:</label>
                <div class="control">
                  <input type="text" class="input" v-model="address" placeholder="Address Billing">
                </div>
              </div>

              <div class="field">
                <div class="columns">
                  <div class="column">
                    <label for="" class="label">City:</label>
                    <div class="control">
                      <input type="text" class="input" v-model="city" placeholder="City">
                    </div>
                  </div>

                  <div class="column">
                    <div class="field">
                      <label for="" class="label">Country:</label>
                      <div class="control" >
                        <select class="input" name="" v-model="country">
                          <option value="HN">Honduras</option>
                          <option value="US">Estados Unidos</option>
                          <option value="CA">Canadá</option>
                          <option value="GT">Guatemala</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <label for="" class="label">State:</label>
                <div class="control">
                  <select name="" class="input" v-model="state">
                    <option value="HN-AT">Atlántida</option>
                    <option value="HN-CR">Cortés</option>
                    <option value="HN-FM">Francisco Morazán</option>
                    <option value="HN-CH">Choluteca</option>
                  </select>
                </div>
              </div>


              <div class="field">
                <label for="" class="label">Phone number:</label>
                <div class="control">
                  <input type="text" class="input" v-model="phone" placeholder="Phone number">
                </div>
              </div>
            </div>

            <div id="divConfirm" class="is-hidden">
              <div class="card m-1">
                <div class="card-content">
                  <div class="content">
                    <div class="level">
                      <div class="level-left">
                        <h6>Deposit</h6>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <p>Amount to deposit: ${{amount}}</p>
                    </div>
                    <div class="column">
                      <p>Description: {{description}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card m-1">
                <div class="card-content">
                  <div class="content">
                    <div class="level">
                      <div class="level-left">
                        <h6>Credit Card</h6>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <p>Number card: {{numberCard}}</p>
                        <p>Expiration date: {{monthExpire}}/{{yearExpire}}</p>
                      </div>
                      <div class="column">
                        <p>Card Holder: {{cardHolder}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card m-1">
                <div class="card-content">
                  <div class="content">
                    <div class="level">
                      <div class="level-left">
                        <h6>Billing Address</h6>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <p>Address: {{address}}</p>
                        <p>{{city}}, {{state}}, {{country}}</p>
                      </div>
                      <div class="column">
                        <p>Phone number: {{phone}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-grouped">
                <div class="control">
                  <button class="button is-primary" @click="onFormSubmit()">Submit</button>
                </div>
                <div class="control">
                  <router-link to="/dashboard" class="button is-danger">Cancel</router-link>
                </div>
              </div>
            </div>
            <div class="has-text-right mt-3">
              <button class="button" @click="previous()">Previous</button>
              <button class="button" @click="next()">Next</button>
            </div>

          </div>
          <div class="column"></div>
        </div>
      </div>

    </form>

  </div>

</template>

<script>
import {getAuth} from "firebase/auth";
import {db} from "@/firebaseConfig";
import {doc, getDoc, setDoc, updateDoc, addDoc, collection} from "firebase/firestore";

const axios = require('axios');

export default {
  name: 'DepositComponent',
  data(){
    return {
      previewDiv: "divDeposit",
      nextDiv: "divCard",
      auth: getAuth(),
      amount: null,
      description: "",
      numberCard: "",
      monthExpire: null,
      yearExpire: null,
      cvv: "",
      cardHolder: "",
      address: "",
      country: "",
      state: "",
      city: "",
      phone:"",
      orderId: "",
      correlativeTransaction: null,
      datos: {},
      user: {}
    }
  },
  mounted() {
    this.getUser(this.auth.currentUser.uid);
  },
  methods: {
    async onFormSubmit(){
      if(!this.validateData()){
        this.$swal.fire({icon: 'error', title: 'Oops!', text: "Something went wrong! It's necessary to complete the entire form"});
        return;
      }
      this.prepareData();
      let transactionName = await this.getCorrelativeTransaction();
      transactionName = ('000000' + transactionName).slice(-7);
      this.saveTransaction(this.auth.currentUser.uid, transactionName);
      /*axios.post('https://api-miwallet.herokuapp.com/deposit', this.datos)
          .then((response) => {
            if(response.data.success === false){
              this.$swal.fire({icon: 'error', title: 'Oops!', text: 'Something went wrong! ' + response.data.message});
              console.log(JSON.stringify(response));
            } else {
              this.$swal.fire({icon: 'success', title: 'Deposit succesfull', text: 'Deposito hecho exitosamente'})
              this.saveTransaction(this.auth.currentUser.uid);
              console.log(JSON.stringify(response))
              //this.$router.push('/dashboard');
            }
          })
          .catch(function (error){
            alert(error);
          });*/
    },
    async saveTransaction(uid, correlativeTransaction){
      const newTransactionRef = collection(db, 'transactions');
      const ref = await addDoc(newTransactionRef, {
        category_name: 'DEPOSIT',
        deposit_order_id: this.datos.orderId,
        status: 'COMPLETED',
        transaction_amount: this.amount,
        transaction_created: new Date(),
        transaction_name: correlativeTransaction,
        user: doc(db,'users/' + uid),
      }).then((response) => {
        this.updateBudget(uid, this.amount);
      }).catch((error) => {
        console.log(error);
      });

      const sequenceRef = doc(db, 'sequences', 'transaction_sequence');
      console.log(sequenceRef);
      const res = await updateDoc(sequenceRef, {correlative: this.correlativeTransaction + 1})
          .then((response) => {
            console.log(response);
          }).catch((error) => {
            console.log(error);
          });
    },
    prepareData(){
      if(this.user.display_name === "Default D."){
        this.user.display_name = this.cardHolder;
      }
      this.datos = {
        amount: this.amount,
        cardNumber: this.numberCard,
        cvv: this.cvv,
        expireMonth: this.monthExpire,
        expireYear: this.yearExpire,
        cardHolder: this.cardHolder,
        address: this.address,
        country: this.country,
        state: this.state,
        city: this.city,
        phone: this.phone,
        customerName: this.user.display_name,
        customerEmail: this.$store.getters.getEmail,
        codeItem: "001",
        titleItem: "Deposit",
        qty: 1,
        orderId: 'MI-' + this.generateOrderId()
      }
    },
    async getCorrelativeTransaction(){
      const docRef = doc(db, 'sequences', 'transaction_sequence');
      const docSnap = await getDoc(docRef);
      console.log("Correlative in getCorrelativeTransaction",docSnap.data().correlative);
      this.correlativeTransaction = docSnap.data().correlative;
      console.log("Value", this.correlativeTransaction);
      return docSnap.data().correlative;
    },
    changeClass(id){
      let oldElement = document.querySelector('li.is-active')
      oldElement.classList.remove('is-active')
      let newElement = document.getElementById(id)
      newElement.classList.add('is-active')
      let divElement = document.getElementById(this.previewDiv)
      let currentDiv;
      switch (id){
        case 'deposit':
          divElement.classList.remove('visible');
          divElement.classList.add('is-hidden');
          currentDiv = document.getElementById('divDeposit');
          currentDiv.classList.add('visible');
          currentDiv.classList.remove('is-hidden');
          this.nextDiv = 'divCard';
          this.previewDiv = 'divDeposit';
          break;
        case 'card':
          divElement.classList.remove('visible');
          divElement.classList.add('is-hidden');
          currentDiv = document.getElementById('divCard');
          currentDiv.classList.add('visible');
          currentDiv.classList.remove('is-hidden');
          this.nextDiv = 'divAddress';
          this.previewDiv = 'divCard';
          break;
        case 'address':
          divElement.classList.remove('visible');
          divElement.classList.add('is-hidden');
          currentDiv = document.getElementById('divAddress');
          currentDiv.classList.add('visible');
          currentDiv.classList.remove('is-hidden');
          this.nextDiv = 'divConfirm';
          this.previewDiv = 'divAddress';
          break;
        case 'confirm':
          divElement.classList.remove('visible');
          divElement.classList.add('is-hidden');
          currentDiv = document.getElementById('divConfirm');
          currentDiv.classList.add('visible');
          currentDiv.classList.remove('is-hidden');
          this.nextDiv = '';
          this.previewDiv = 'divConfirm';
          break;
      }
    },
    async getUser(uid){
      const docRef = doc(db, 'users', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()){
        this.user = docSnap.data();
      } else {
        console.log("No such document");
      }
    },
    generateOrderId(){
      let value = new Date();
      return '' + value.getDate() + ("0" + (value.getMonth() + 1)).slice(-2) + value.getFullYear().toString().slice(-2) + value.getHours() + value.getMinutes() + value.getSeconds() + value.getMilliseconds();
    },
    next(){
      if(this.previewDiv === 'divConfirm'){
        console.log('No');
      }else if(this.previewDiv === 'divDeposit'){
        this.changeClass('card');
      }else if(this.previewDiv === 'divCard'){
        this.changeClass('address');
      }else {
        this.changeClass('confirm')
      }
    },
    previous(){
      if(this.previewDiv === 'divDeposit'){
        console.log('NO');
      }else if(this.previewDiv === 'divCard'){
        this.changeClass('deposit');
      }else if(this.previewDiv === 'divAddress'){
        this.changeClass('card');
      }else {
        this.changeClass('address');
      }
    },
    async updateBudget(uid, amountDeposit) {
      const budgetRef = doc(db, 'budgets', uid);
      const currentBudget = await getDoc(budgetRef);
      const res = await updateDoc(budgetRef, {budget_amount: parseFloat(currentBudget.data().budget_amount + amountDeposit)})
    },
    validateData(){
      if(this.amount === null)
        return false;
      if(this.description === "")
        return false;
      if(this.numberCard === "")
        return false;
      if(this.monthExpire === null)
        return false;
      if(this.yearExpire === null)
        return false;
      if(this.cvv === "")
        return false;
      if(this.cardHolder === "")
        return false;
      if(this.address === "")
        return false;
      if(this.country === "")
        return false;
      if(this.state === "")
        return false;
      if(this.city === "")
        return false;
      if(this.phone === "")
        return false;
      if(this.orderId === "")
        return false;
      return true;
    }
  }
}
</script>

<style>
.visible{
  visibility: visible;
}

@media screen and (max-width: 768px) {
  .content h6{
    font-size: 0.9rem;
  }

  p{
    font-size: 0.8rem;
  }

  .label{
    font-size: 0.8rem;
  }

  .button{
    font-size: 0.8rem;
    height: 2em;
  }

  .input{
    font-size: 0.8rem;
    height: 2em;
  }

  .breadcrumb{
    font-size: 0.8rem;
  }

  .title{
    font-size: 1.5rem;
  }

  .subtitle{
    font-size: 1rem;
  }
}
</style>