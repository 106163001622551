<template>
  <section class="section">
    <div class="columns">
      <div class="column">
        <img src="../assets/images/celmonedasweb.png" alt="">
      </div>
      <div class="column">
        <h1 class="title" style="font-weight: 800; color: #FBB50A;">ESCRÍBENOS</h1>
        <h1 class="subtitle" style="color: #FBB50A">TUS DUDAS SIN PENA</h1>
        <form ref="form" @submit.prevent="onFormSubmit" method="post">
          <div class="field" style="margin-left: 25%;">
            <div class="control" style="width: 70%;">
              <input class="input" type="text" style="border-radius: 10px; border: 1px solid #286DC2;" v-model="name" name="name" placeholder="Nombre" required>
            </div>
          </div>

          <div class="field" style="margin-left: 25%;">
            <div class="control" style="width: 70%;">
              <input class="input" type="email" style="border-radius: 10px; border: 1px solid #286DC2;" placeholder="Email" name="email" v-model="email" required>
            </div>
          </div>

          <div class="field" style="margin-left: 25%;">
            <div class="control" style="width: 70%;">
              <input class="input" type="text" style="border-radius: 10px; border: 1px solid #286DC2;" placeholder="Teléfono" name="phone" v-model="phone" required>
            </div>
          </div>

          <div class="field" style="margin-left: 25%;">
            <div class="control" style="width: 70%;">
              <textarea class="textarea" style="border-radius: 10px; border: 1px solid #286DC2;" placeholder="Mensaje" name="message" v-model="message" required></textarea>
            </div>
          </div>

          <div>
            <input type="submit" style="border-radius: 10px; background-color: #286DC2; display: flex; margin-left: 25%;" class="button is-primary" value="Enviar">
          </div>
        </form>
      </div>
    </div>
  </section>

</template>

<script>
import emailjs from '@emailjs/browser';
export default {
    name: 'ContactComponent',
  data(){
      return {
        name: '',
        phone: '',
        email: '',
        message: ''
      }
  },
  methods: {
      onFormSubmit(){
        emailjs.sendForm('MIContact', 'template_rw1tw0b', this.$refs.form, 'n09hIyVglK80gr9P8')
            .then((result) => {
              console.log('Success!', result.text);
              this.$swal.fire({icon: 'success', title: 'Mensaje recibido.', text: 'Gracias por comunicarte con nosotros, pronto te contactaremos.'})
              this.$router.push('/');
            }, (error) => {
              console.log('Failed!', error.text);
              this.$swal.fire({icon: 'error', title: 'Oops!', text: 'Ocurrió un error al enviar su mensaje.'});
            });
      }
  }
}
</script>
<style>
.title{
  margin-top: 100px;
  font-size: 3rem;
}

.subtitle{
  font-size: 2rem;
}
</style>