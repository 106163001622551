//---------------------------- FIREBASE ------------------------------
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDONOhAUNe1GOsZfxzNtt4Frike2x4R5A8",
  authDomain: "myeasywalletmelvatech.firebaseapp.com",
  projectId: "myeasywalletmelvatech",
  storageBucket: "myeasywalletmelvatech.appspot.com",
  messagingSenderId: "814571246624",
  appId: "1:814571246624:web:ba954fcae53cd0d1b89845",
  measurementId: "G-1T1KWGNG1Z"
};

//Conexión a base de datos de Prueba
const firebaseConfigPrueba = {
  apiKey: "AIzaSyDONOhAUNe1GOsZfxzNtt4Frike2x4R5A8",
  authDomain: "myeasywalletmelvatech.firebaseapp.com",
  projectId: "myeasywalletmelvatech",
  storageBucket: "myeasywalletmelvatech.appspot.com",
  messagingSenderId: "814571246624",
  appId: "1:814571246624:web:ba954fcae53cd0d1b89845",
  measurementId: "G-1T1KWGNG1Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const appPrueba = initializeApp(firebaseConfigPrueba);

const db = getFirestore(app);
//const dbPrueba = getFirestore(appPrueba);

const functions = getFunctions(app);
//const functionsPrueba = getFunctions(appPrueba);

export {app, db, functions }

/*
apiKey: "AIzaSyC6dSFvj3i7_s0ygYFVb5e4YrTpZDBXdWg",
  authDomain: "miwallet-36c00.firebaseapp.com",
  projectId: "miwallet-36c00",
  storageBucket: "miwallet-36c00.appspot.com",
  messagingSenderId: "863103644093",
  appId: "1:863103644093:web:acd95b67e2b7776fe1a2d4",
  measurementId: "G-GYCM8GRQ29"



apiKey: "AIzaSyDONOhAUNe1GOsZfxzNtt4Frike2x4R5A8",
  authDomain: "myeasywalletmelvatech.firebaseapp.com",
  projectId: "myeasywalletmelvatech",
  storageBucket: "myeasywalletmelvatech.appspot.com",
  messagingSenderId: "814571246624",
  appId: "1:814571246624:web:ba954fcae53cd0d1b89845",
  measurementId: "G-1T1KWGNG1Z"
 */