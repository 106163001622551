<template>
    
    <div class="background-body">
        <img src="../assets/modertextwhite.png" style="margin-top: 10px;" alt="" />
        <div class="container box">
        <div class="columns">
            <div class="column"></div>
            <div class="column is-8">
                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label class="label" for="">Email:</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <div class="columns">
                                    <div class="column is-11">
                                        <input type="text" class="input" placeholder="User" v-model="email" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-label is-normal">
                        <label for="" class="label">Password:</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <div class="control">
                                <div class="columns">
                                    <div class="column is-11">
                                        <input type="password" class="input" placeholder="Password" v-model="password" />
                                    </div>
                                    <div class="column">
                                        <svg-icon type="mdi" :path="path"></svg-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                <div>
                  <router-link to="/">Forgot your password?</router-link>
                </div>
                
                <button class="button is-dark" @click="signIn">Login</button>
                <div>
                  <router-link to="/register" >Create your account</router-link>
                </div>
                
            </div>
            <div class="column"></div>
        </div>
        
    </div>
    </div>
    
</template>

<script>
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiEye } from '@mdi/js'

export default {
    name: 'LoginComponent',
    data() {
        return {
            email: "",
            password: "",
            path: mdiEye
        }
    },
    components: {
        SvgIcon
    },
    methods: {
        signIn() {
            const auth = getAuth();
            signInWithEmailAndPassword(auth, this.email, this.password).then((data) => {
              auth.onAuthStateChanged(user => {
                if(user){
                  this.$store.commit('setLoggedIn', true);
                  this.$store.dispatch('fetchUser', user)
                }
              });
              this.$router.push('/dashboard');
            }).catch((error) => {
                this.$swal.fire({icon: 'error', title: 'Oops!', text: 'Something went wrong! Email or password incorrect.'});
            });
        }
    }
}
</script>

<style>
.container{
    background-color: #FFF;
    border-radius: 10px;
    margin-top: 10px;
    width: 80%;
}

.background-body{
  height: 100vh;
  width: 100vw;
  background-color: #E6AF2E;
  
}

</style>