<template>
  
  <footer id="footer">
    <img src="../assets/images/rectangle.png" alt="">
    <div class="columns is-gapless is-multiline is-mobile is-centered">
      <div class="column is-full">
        
      </div>
      <div class="div-element has-text-centered" >
        <div>
          <p><strong> Descarga MIApp </strong></p>
        </div>

          <div class="caja-icono">
            <a href="https://play.google.com/store/apps/details?id=com.melvatech.modernInvestments&hl=es" target="_blank" >
              <img src="../assets/images/iconops.png" class="icono" alt="">
            </a>
          </div>
        <div class="caja-icono">
          <a target="_blank" href="https://apps.apple.com/hn/app/modern-investments/id1639632869" >
            <img src="../assets/images/iconoas.png" class="icono" alt="">
          </a>
        </div>
      </div>

      <div class=" div-element is-centered" >
        <div>
          <p><strong>Escríbenos</strong></p>
        </div>

        <div class="caja-icono" >
          <a target="_blank" href="https://api.whatsapp.com/send?phone=50432805582&text=Estamos%20para%20servirle.%20Escribe%20%3EHOLA%3C%20en%20el%20mensaje%20y%20espera%20nuestro%20asistente%20en%20l%C3%ADnea.%20ModernInvestments.">
            <img src="../assets/images/iconowa.png" class="icono" alt="">
          </a>
        </div>

        <div class="caja-icono">
          <a target="_blank" href="https://www.facebook.com/modinves">
            <img src="../assets/images/iconoface.png" class="icono" alt="">
          </a>
        </div>

        <div class="caja-icono">
          <a target="_blank" href="https://www.instagram.com/modinves/">
            <img src="../assets/images/iconoig.png" class="icono" alt="">
          </a>
        </div>
      </div>
      <div class="column is-full">
        <img src="../assets/images/rectangle.png" alt="">
        <p><strong>Modern Investments</strong></p>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style scoped>
.icono{
  width: 20px;
  height: 20px;
}

.caja-icono{
  display: inline-block;
  width: 30px;
}

.div-element{
  width: 25%;
}

@media (max-width: 600px) {
  .div-element{
    width: 50%;
  }
}
</style>