<template>
  <h1 class="title">My Withdrawals</h1>
  <div class="container">
    <table class="table is-striped is-hoverable is-fullwidth">
      <thead>
      <tr>
        <th>#</th>
        <th>Amount</th>
        <th>Date</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(transaction, index) in transactions" :key="transaction.id">
        <th v-if="transaction.category_name == 'WITHDRAWAL'">{{index}}</th>
        <th v-if="transaction.category_name == 'WITHDRAWAL'">{{transaction.transaction_amount}}</th>
        <th v-if="transaction.category_name == 'WITHDRAWAL'">{{$filters.timestampToDate(transaction.transaction_created)}}</th>
        <th v-if="transaction.category_name == 'WITHDRAWAL'">{{transaction.status}}</th>
        <th v-if="transaction.category_name == 'WITHDRAWAL' && transaction.status =='PENDING'">
          <router-link to="" class="button is-danger">Cancel</router-link>
        </th>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getAuth } from '@firebase/auth'
import {collection, doc, getDocs, query, where} from "firebase/firestore";
import {db} from "@/firebaseConfig";

export default {
  name: 'WithdrawalsComponent',
  data(){
    return{
      auth: getAuth(),
      transactions: []
    }
  },
  created() {
    this.getTransactions(this.auth.currentUser.uid);
  },
  methods: {
    async getTransactions(uid){
      const userRef = doc(db, 'users', uid);
      const q = query(collection(db, 'transactions'), where("user", "==", userRef));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.transactions.push({id: doc.id, ...doc.data()});
      });
    }
  }
}
</script>
<style></style>