<template>
  <h1 class="title">MIPago</h1>
  <div class="section pt-4 pb-0">
    <nav class="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
      <ul class="container">
        <li id="deposit" class="is-active link"><a aria-current="step" @click="changeClass('deposit')">MIPago</a></li>
        <li id="card"><a @click="changeClass('card')">Card</a></li>
        <li id="address"><a @click="changeClass('address')">Billing Address</a></li>
        <li id="confirm" class=""><a @click="changeClass('confirm')">Confirm</a></li>
      </ul>
    </nav>
    <form action="" ref="form" @submit.prevent="onFormSubmit" >
      <div class="container">
        <div class="columns">
          <div class="column"></div>
          <div class="column is-8">
            <div id="divDeposit" class="visible">
              <div class="field">
                <label for="" class="label">Amount:</label>
                <div class="control">
                  <input type="number" class="input" v-model="amount" placeholder="Amount to deposit" />
                </div>
              </div>
              <div class="field">
                <label for="" class="label">Account number:</label>
                <div class="control">
                  <input type="text" class="input" v-model="account" placeholder="Destination account number" />
                </div>
              </div>
              <div class="field">
                <label for="" class="label">Email</label>
                <div class="control">
                  <input type="email" class="input" v-model="emailClient" placeholder="Your email" />
                </div>
              </div>
            </div>

            <div id="divCard" class="is-hidden">
              <div class="field">
                <label for="" class="label">Number card:</label>
                <div class="control">
                  <input type="text" class="input" v-model="numberCard" placeholder="Number card...">
                </div>
              </div>

              <div class="field">
                <div class="columns">
                  <div class="column">
                    <label for="" class="label">Month expire:</label>
                    <div class="control">
                      <select class="input" v-model="monthExpire" name="" id="month">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </div>
                  </div>
                  <div class="column">
                    <label for="" class="label">Year expire:</label>
                    <div class="control">
                      <select name="" class="input" v-model="yearExpire">
                        <option value="23">2023</option>
                        <option value="24">2024</option>
                        <option value="25">2025</option>
                        <option value="26">2026</option>
                        <option value="27">2027</option>
                        <option value="28">2028</option>
                        <option value="29">2029</option>
                        <option value="2030">2030</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <label for="" class="label">Cvv:</label>
                <div class="control">
                  <input type="password" class="input" v-model="cvv" placeholder="Your code cvv">
                </div>
              </div>

              <div class="field">
                <label for="" class="label">Card holder:</label>
                <div class="control">
                  <input type="text" class="input" v-model="cardHolder" placeholder="Card holder...">
                </div>
              </div>
            </div>

            <div id="divAddress" class="is-hidden">
              <div class="field">
                <label for="" class="label">Address:</label>
                <div class="control">
                  <input type="text" class="input" v-model="address" placeholder="Address Billing">
                </div>
              </div>

              <div class="field">
                <div class="columns">
                  <div class="column">
                    <label for="" class="label">City:</label>
                    <div class="control">
                      <input type="text" class="input" v-model="city" placeholder="City">
                    </div>
                  </div>

                  <div class="column">
                    <div class="field">
                      <label for="" class="label">Country:</label>
                      <div class="control" >
                        <select class="input" name="" v-model="country">
                          <option value="HN">Honduras</option>
                          <option value="US">Estados Unidos</option>
                          <option value="CA">Canadá</option>
                          <option value="GT">Guatemala</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <label for="" class="label">State:</label>
                <div class="control">
                  <select name="" class="input" v-model="state">
                    <option value="HN-AT">Atlántida</option>
                    <option value="HN-CR">Cortés</option>
                    <option value="HN-FM">Francisco Morazán</option>
                    <option value="HN-CH">Choluteca</option>
                  </select>
                </div>
              </div>

              <div class="field">
                <label for="" class="label">Phone number:</label>
                <div class="control">
                  <input type="text" class="input" v-model="phone" placeholder="Phone number">
                </div>
              </div>
              <div class="field">
                <label for="" class="label">Zip Code:</label>
                <div class="control">
                  <input type="text" class="input" v-model="zip" placeholder="Zip code">
                </div>
              </div>
            </div>

            <div id="divConfirm" class="is-hidden">
              <div class="card m-1">
                <div class="card-content">
                  <div class="content">
                    <div class="level">
                      <div class="level-left">
                        <h6>Deposit</h6>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <p>Amount to deposit: $ {{amount}}</p>
                    </div>
                    <div class="column">
                      <p>Email for confirmation: {{emailClient}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card m-1">
                <div class="card-content">
                  <div class="content">
                    <div class="level">
                      <div class="level-left">
                        <h6>Credit Card</h6>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <p>Number card: {{numberCard}}</p>
                        <p>Expiration date: {{monthExpire}}/{{yearExpire}}</p>
                      </div>
                      <div class="column">
                        <p>Card Holder: {{cardHolder}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card m-1">
                <div class="card-content">
                  <div class="content">
                    <div class="level">
                      <div class="level-left">
                        <h6>Billing Address</h6>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <p>Address: {{address}}</p>
                        <p>{{city}}, {{state}}, {{country}}</p>
                      </div>
                      <div class="column">
                        <p>Phone number: {{phone}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-grouped">
                <div class="control">
                  <input type="submit" class="button is-primary" value="Submit">
                </div>
                <div class="control">
                  <router-link to="/dashboard" class="button is-danger">Cancel</router-link>
                </div>
              </div>
            </div>

          </div>
          <div class="column"></div>
        </div>
      </div>

    </form>
    <div class="columns">
      <div class="column"></div>
      <div class="column"></div>
      <div class="column">
        <div class="has-text-right mt-3">
          <button class="button" @click="previous()">Previous</button>
          <button class="button" @click="next()">Next</button>
        </div>
      </div>
      <div class="column is-2"></div>
    </div>

    <br> <br><br>

  </div>
</template>

<script>
import {db} from "@/firebaseConfig";
import {doc, getDoc, updateDoc, addDoc, collection, query, where, getDocs} from "firebase/firestore";
import {getAuth, signInWithEmailAndPassword, signOut} from "firebase/auth";

const axios = require('axios');

export default {
  name: 'RemesasComponent',
  data(){
    return {
      previewDiv: "divDeposit",
      nextDiv: "divCard",
      account: "",
      emailClient: "",
      amount: null,
      numberCard: "",
      monthExpire: null,
      yearExpire: null,
      cvv: "",
      cardHolder: "",
      address: "",
      country: "",
      state: "",
      city: "",
      phone:"",
      orderId: "",
      zip: "",
      correlativeTransaction: null,
      datos: {},
      user: {},
      comisionFija: 1,
      comisionVariable: 0.00,
      totalARecibir: null
    }
  },
  mounted() {
    this.signIn();
  },
  methods: {
    async onFormSubmit(){
      if(!this.validateData()){
        this.$swal.fire({icon: 'error', title: 'Oops!', text: "Something went wrong! It's necessary to complete the entire form"});
        return;
      }
      const currencies = await fetch('https://open.er-api.com/v6/latest/USD')
          .then(response => {
            return response.json();
          });

      let toRate = currencies.rates['HNL'];
      this.comisionFija = (1 / toRate).toFixed(2);

      this.comisionVariable = this.amount * 0.005;
      this.totalARecibir = (this.amount - this.comisionFija - this.comisionVariable).toFixed(2);

      this.prepareData();
      axios.post('https://api-miwallet.herokuapp.com/deposit', this.datos)
          .then((response) => {
            if(response.data.success === false){
              this.$swal.fire({icon: 'error', title: 'Oops!', text: 'Something went wrong! ' + response.data.message});
              console.log(JSON.stringify(response.data));
              console.log(response);
            } else {
              this.$swal.fire({icon: 'success', title: 'Deposit succesfull', text: 'Pago hecho exitosamente.'})
              this.saveTransaction();
              this.$router.push('/');
            }
          })
          .catch(function (error){
            alert(error);
          });
    },
    async saveTransaction(){
      const newTransactionRef = collection(db, 'transactions');

      const ref = await addDoc(newTransactionRef, {
        category_name: 'MIPAGO',
        deposit_order_id: this.datos.orderId,
        status: 'COMPLETED',
        transactionAmount: this.totalARecibir,
        transactionTime: new Date(),
        destinationAccount: this.account,
        emailConfirm: this.emailClient
      }).then((response) => {
        this.updateBudget(this.account, this.totalARecibir); //Enviamos totalARecibir por la comisión cobrada
      }).catch((error) => {
        console.log(error);
      });
    },
    async convertHNLtoUSD(){
      const currencies = await fetch('https://open.er-api.com/v6/latest/USD')
          .then(response => {
            return response.json();
          });

      let toRate = currencies.rates['HNL'];
      this.amount = (this.amount / toRate).toFixed(2);

    },
    prepareData(){
      this.user.display_name = this.cardHolder;

      this.datos = {
        amount: this.amount,
        cardNumber: this.numberCard,
        cvv: this.cvv,
        expireMonth: this.monthExpire,
        expireYear: this.yearExpire,
        cardHolder: this.cardHolder,
        address: this.address,
        country: this.country,
        state: this.state,
        city: this.city,
        phone: this.phone,
        codeItem: "002",
        titleItem: "MIPago",
        qty: 1,
        orderId: 'MI-' + this.generateOrderId(),
        customerName: this.cardHolder,
        customerEmail: this.emailClient
      }
    },
    changeClass(id){
      let oldElement = document.querySelector('li.is-active')
      oldElement.classList.remove('is-active')
      let newElement = document.getElementById(id)
      newElement.classList.add('is-active')
      let divElement = document.getElementById(this.previewDiv)
      let currentDiv;
      switch (id){
        case 'deposit':
          divElement.classList.remove('visible');
          divElement.classList.add('is-hidden');
          currentDiv = document.getElementById('divDeposit');
          currentDiv.classList.add('visible');
          currentDiv.classList.remove('is-hidden');
          this.nextDiv = 'divCard';
          this.previewDiv = 'divDeposit';
          break;
        case 'card':
          divElement.classList.remove('visible');
          divElement.classList.add('is-hidden');
          currentDiv = document.getElementById('divCard');
          currentDiv.classList.add('visible');
          currentDiv.classList.remove('is-hidden');
          this.nextDiv = 'divAddress';
          this.previewDiv = 'divCard';
          break;
        case 'address':
          divElement.classList.remove('visible');
          divElement.classList.add('is-hidden');
          currentDiv = document.getElementById('divAddress');
          currentDiv.classList.add('visible');
          currentDiv.classList.remove('is-hidden');
          this.nextDiv = 'divConfirm';
          this.previewDiv = 'divAddress';
          break;
        case 'confirm':
          this.convertHNLtoUSD();
          divElement.classList.remove('visible');
          divElement.classList.add('is-hidden');
          currentDiv = document.getElementById('divConfirm');
          currentDiv.classList.add('visible');
          currentDiv.classList.remove('is-hidden');
          this.nextDiv = '';
          this.previewDiv = 'divConfirm';
          break;
      }
    },
    generateOrderId(){
      let value = new Date();
      return '' + value.getDate() + ("0" + (value.getMonth() + 1)).slice(-2) + value.getFullYear().toString().slice(-2) + value.getHours() + value.getMinutes() + value.getSeconds() + value.getMilliseconds();
    },
    next(){
      if(this.previewDiv === 'divConfirm'){
        console.log('No');
      }else if(this.previewDiv === 'divDeposit'){
        this.changeClass('card');
      }else if(this.previewDiv === 'divCard'){
        this.changeClass('address');
      }else {
        this.changeClass('confirm')
      }
    },
    previous(){
      if(this.previewDiv === 'divDeposit'){
        console.log('NO');
      }else if(this.previewDiv === 'divCard'){
        this.changeClass('deposit');
      }else if(this.previewDiv === 'divAddress'){
        this.changeClass('card');
      }else {
        this.changeClass('address');
      }
    },
    async updateBudget(account, amountDeposit) {
      const q = query(collection(db, "budgets"), where("budgetName", "==", account));
      const querySnapshot = await getDocs(q);
      let uid = '';
      querySnapshot.forEach((doc) => {
        uid = doc.id;
      });
      const budgetRef = doc(db,'budgets', uid);
      const currentBudget = await getDoc(budgetRef);
      console.log('Current Budget', currentBudget.data().budgetAmount);
      console.log('Type current budget', typeof(currentBudget.data().budgetAmount));
      const res = await updateDoc(budgetRef, {budgetAmount: parseFloat(currentBudget.data().budgetAmount) + parseFloat(amountDeposit)});
    },
    validateData(){
      if(this.amount === null)
        return false;
      if(this.numberCard === "")
        return false;
      if(this.monthExpire === null)
        return false;
      if(this.yearExpire === null)
        return false;
      if(this.cvv === "")
        return false;
      if(this.cardHolder === "")
        return false;
      if(this.address === "")
        return false;
      if(this.country === "")
        return false;
      if(this.state === "")
        return false;
      if(this.city === "")
        return false;
      if(this.phone === "")
        return false;
      if(this.emailClient === "")
        return false;
      if(this.account === "")
        return false;
      return true;
    },
    signIn() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, "modinves@modinves.com", "MI2@22UseR$").then((data) => {
      }).catch((error) => {
        console.log(error);
      });
    }
}
}
</script>

<style scoped>

</style>