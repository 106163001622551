<template>
  <section class="section">
    <div class="columns">
      <div class="column" >
        <img src="../assets/images/helicopterotexto.png" alt="">
      </div>
      <div class="column">
        <h1 class="title-services" style="color: #FBB50A;">Ofrecemos</h1>
        <p style="text-align: left;">Soluciones de superación para PYMES y personas regulares, esto lo hacemos a través de varios servicios como ser: POS accesible, mas intereses por tus ahorros, atención personalizada,
          opción a préstamo, membresía para envíos desde USA a HN, facilidad para cambio de $ a LP, accesibilidad de hacer retiros digitales o conseguir el dinero en físico sin hacer filas,
          remesas sin filas, opción para prestamos a PYMES para traer productos de USA a HN con crédito de MI App, mayores beneficios en intereses para cuentas PYMES, beneficios para empresas
          que pagan planilla a través de <strong>MIApp</strong>. Entre otros.</p>
        <hr style="background-color: black; width: 30%;">
        <h1 class="title-services" style="color: #286DC2;">Seguridad</h1>
        <p style="text-align: left;">El temor de cualquier cosa en el mundo digital existe. Para calmar los aires te ofrecemos la última tecnología de visa y máster 3D- Secure, con esta tecnología evitamos los fraudes
          con tarjetas de débito y crédito. Ahora te estarás preguntando qué asegura mi dinero, pues en comparación a otros del mismo rubro <strong>MIApp</strong> te asegura el 80% de tus ahorros, teniendo
          fondos propios de <strong>MIApp</strong> bajo el mismo algoritmo de generación de intereses de <strong>MIApp</strong>. Si deseas algún método de seguridad extra comunícate con soporte y te lo ofrecemos, pero puede
          inquirir gastos extras.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'ProductsComponent'
}
</script>
<style>
.title-services{
  font-weight: 800; font-size: 2rem; text-align: left;
}

@media screen and (max-width: 768px) {
  .title-services{
    font-size: 1.2rem;
    font-weight: 700;
  }
}
</style>