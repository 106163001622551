<template>
    <section class="section">
        <div class="container">
            <h1 class="title">
                Edit profile
            </h1>
            <div class="columns is-centered">
                <form @submit.prevent="onFormSubmit">
                <!-- <div class="file">
                    <input type="file" class="input" name="photo">
                </div> -->
                <div class="field">
                    <label class="label">Your name:</label>
                    <div class="control">
                        <input class="input" type="text" placeholder="Type your name...">
                    </div>
                </div>

                <div class="field">
                    <label for="" class="label">Your age</label>
                    <div class="control">
                        <input type="number" class="input" placeholder="Type your age...">
                    </div>
                </div>
                <div class="field is-grouped">
                    <div class="control">
                        <input type="submit" class="button is-primary" value="Submit">
                    </div>
                    <div class="control">
                        <router-link to="/my-profile" class="button is-danger">Cancel</router-link>
                    </div>
                </div>
            </form>
            </div>
            
        </div>
        
    </section>
</template>

<script>
export default {
    name: 'EditProfile',
    methods: {
        onFormSubmit(){
            console.log("Enviando datos...")
        }
    },
}
</script>

<style>
</style>