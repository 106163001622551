<template>
    <section class="section">
        <div class="container">
            <h1 class="title">My profile</h1>
            <!--<img src="../assets/logo.png" alt="" /> -->
            <p>Your name: Efraín Rivera</p>
            <p>Your age: 27</p>

            <div class="columns">
                <div class="column is-2"></div>
                <div class="column is-8" >
                    <div class="block">
                        <router-link to="/edit-profile" class="button is-warning is-fullwidth">Edit Profile</router-link>
                    </div>
                    <div class="block">
                        <button class="button is-warning is-fullwidth">Change Password</button>
                    </div>
                    <div class="block">
                        <button class="button is-warning is-fullwidth">Privacy Policy</button>
                    </div>
                    
                </div>
                <div class="column is-2"></div>
            </div>
        </div> 
    </section> 
</template>

<script>
export default {
    name: 'ProfileComponent'
}
</script>

<style></style>