<template>
  <h1 class="title">Withdrawal</h1>

    <form ref="form" @submit.prevent="sendData">
      <div class="container">
        <div class="tile is-ancestor">
          <div class="tile is-parent">
            <div class="tile is-child notification is-warning">
              <p class="title">Available</p>
              <p class="subtitle">${{balance}}</p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column"></div>
          <div class="column is-8">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label for="" class="label">Name:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input type="text" v-model="name" class="input" name="name" placeholder="Your name">
                  </div>
                </div>
              </div>
            </div>
            
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label for="" class="label">Account number:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input type="text" v-model="accountNumber" class="input" name="accountNumber" placeholder="Your account number">
                  </div>
                </div>
              </div>
            </div>
            
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label for="" class="label">Bank name:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input type="text" v-model="bankName" class="input" name="bankName" placeholder="Name of your bank...">
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label for="" class="label">Currency:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <select v-model="currency" class="input" name="currency">
                      <option value="">Select your option</option>
                      <option value="HNL">Lempira</option>
                      <option value="GTQ">Quetzales</option>
                      <option value="USD">Dólar americano</option>
                      <option value="CAD">Dólar canadiense</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label for="" class="label">Country:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <select v-model="country" class="input" name="country">
                      <option value="">Select your option</option>
                      <option value="HN">Honduras</option>
                      <option value="GT">Guatemala</option>
                      <option value="USA">Estados Unidos</option>
                      <option value="CA">Canadá</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label for="" class="label">Type account:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <select v-model="typeAccount" class="input" name="typeAccount">
                      <option value="">Select your option</option>
                      <option value="Svn">Saving account</option>
                      <option value="Chk">Checking account</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label for="" class="label">Amount:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input type="number" class="input" v-model="amount" name="amount" placeholder="Amount to withdrawal" />
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <input type="submit" class="button is-primary" value="Submit">
              </div>
              <div class="control">
                <router-link to="/dashboard" class="button is-danger">Cancel</router-link>
              </div>
            </div>
          </div>
          <div class="column"></div>
        </div>
      </div>
    </form>
</template>

<script>
import emailjs from '@emailjs/browser';
import {addDoc, collection, doc, getDoc, updateDoc} from "firebase/firestore";
import {db} from "@/firebaseConfig";
import {getAuth} from "firebase/auth";

export default {
  name: 'WithdrawalComponent',
  data(){
    return {
      amount: null,
      name: "",
      accountNumber: "",
      bankName: "",
      currency: "",
      country: "",
      typeAccount: "",
      balance: this.$store.state.balance,
      correlativeTransaction: null,
      auth: getAuth(),
    }
  },
  methods: {
    async sendData() {
      if(!this.validateData()){
        this.$swal.fire({icon: "error", title: "Oops!", text:"Something went wrong! It's necessary to complete the entire form."});
        return;
      }

      if(this.amount > this.balance){
        this.$swal.fire({icon: "error", title: "Oops!", text:"Something went wrong! IThe requested amount is greater than your available balance"});
        return;
      }
      let transactionName = await this.getCorrelativeTransaction();
      transactionName = ('000000' + transactionName).slice(-7);
      emailjs.sendForm('MIWithdrawal', 'template_rw1tw0b', this.$refs.form, 'n09hIyVglK80gr9P8')
          .then((result) => {
            console.log('Success!', result.text);
          }, (error) => {
            console.log('Failed!', error.text);
          });
      this.$swal.fire({icon: 'success', title: 'Withdrawal succesfull', text: 'Withdrawal done, wait from 6 hours to 24 hours.'});
      await this.saveTransaction(this.auth.currentUser.uid, transactionName);
      this.$router.push('/dashboard');
    },
    async saveTransaction(uid, correlativeTransaction){
      const newTransactionRef = collection(db, 'transactions');
      const ref = await addDoc(newTransactionRef, {
        category_name: 'WITHDRAWAL',
        status: 'PENDING',
        transaction_amount: this.amount,
        transaction_created: new Date(),
        transaction_name: correlativeTransaction,
        user: doc(db,'users/' + uid),
      }).then((response) => {
        this.updateBudget(uid, this.amount, this.currency);
      }).catch((error) => {
        console.log(error);
      });

      const sequenceRef = doc(db, 'sequences', 'transaction_sequence');
      console.log(sequenceRef);
      const res = await updateDoc(sequenceRef, {correlative: this.correlativeTransaction + 1})
          .then((response) => {
            console.log(response);
          }).catch((error) => {
            console.log(error);
          });
    },
    generateOrderId(){
      let value = new Date();
      return '' + value.getDate() + ("0" + (value.getMonth() + 1)).slice(-2) + value.getFullYear().toString().slice(-2) + value.getHours() + value.getMinutes() + value.getSeconds() + value.getMilliseconds();
    },
    async updateBudget(uid, amountWithdrawal, currency) {
      const budgetRef = doc(db, 'budgets', uid);
      const currentBudget = await getDoc(budgetRef);
      const currencies = await fetch('https://open.er-api.com/v6/latest/USD')
          .then(response => {
            return response.json();
          });

      let toRate = currencies.rates[currency];
      amountWithdrawal = (amountWithdrawal / toRate).toFixed(2);
      alert('Solicitud de retiro: ' + amountWithdrawal);
      const res = await updateDoc(budgetRef, {budget_amount: parseFloat(currentBudget.data().budget_amount - amountWithdrawal)});
    },
    displayResults(arreglo, currency){
      let toRate = arreglo.rates[currency];
      let result = (200 / toRate).toFixed(2);
      alert(toRate + ' / ' +  ' = ' + result);
    },
    async getCorrelativeTransaction(){
      const docRef = doc(db, 'sequences', 'transaction_sequence');
      const docSnap = await getDoc(docRef);
      console.log("Correlative in getCorrelativeTransaction",docSnap.data().correlative);
      this.correlativeTransaction = docSnap.data().correlative;
      console.log("Value", this.correlativeTransaction);
      return docSnap.data().correlative;
    },
    validateData(){
      if(this.amount === null) return false;
      if(this.name === "") return false;
      if(this.accountNumber === "") return false;
      if(this.bankName === "") return false;
      if(this.currency === "") return false;
      if(this.typeAccount === "") return false;
      return true;
    }
  }
}
</script>

<style></style>