<template>
<section class="section" >
    <div class="container">
        <h1 class="title">Welcome, {{user.display_name}} </h1>
        <p>Account: {{budget.budget_name}}</p>
        <p>Total Balance: ${{budget.budget_amount}}</p>
    </div>
</section >
    <div class="columns is-centered is-mobile">
        <div class="column is-half " >
            <div class="tile is-ancestor">
                <div class="tile is-parent"></div>
                <div class="tile is-parent">
                    <article class="tile is-child box notification is-warning">
                    <p class="subtitle">Balance</p>
                    <p class="">${{budget.budget_amount}}</p>
                    </article>
                </div>
                <div class="tile is-parent">
                    <article class="tile is-child box notification is-dark">
                    <p class="subtitle">Profits</p>
                    <p class="">${{budget.profits}}</p>
                    </article>
                </div>
                <div class="tile is-parent"></div>
            </div>
            <nav class="level">
                <div class="level-item">
                    <router-link to="/deposit" class="button is-fullwidth">Deposit</router-link>
                </div>
                <!--<div class="level-item">
                    <router-link to="/transfer" class="button is-fullwidth">Transfer</router-link>
                </div>-->
                <div class="level-item">
                    <router-link to="/withdrawal" class="button is-fullwidth">Withdrawal</router-link>
                </div>
            </nav>
            <div class="tile is-ancestor">
                <div class="tile is-parent is-vertical">
                  <p class="subtitle" v-if="transactions.length == 0">No transactions yet</p>
                  <div v-for="transaction in transactions" :key="transaction.id">
                    <article class="tile is-child notification is-warning" v-if="transaction.category_name != 'WITHDRAWAL'" >
                        <p class="title">${{transaction.transaction_amount}}</p>
                        <p class="subtitle">{{ transaction.category_name }}</p>
                        <p>Fecha: {{$filters.timestampToDate(transaction.transaction_created)}}</p>
                      <hr>
                    </article>

                  </div>


                </div>
            </div>
        </div>   
    </div>
    
</template>

<script>
import { getAuth } from '@firebase/auth'
import { db } from "@/firebaseConfig"
import { getDoc, doc, where, getDocs, query, collection, orderBy} from "firebase/firestore"

export default {
  name: 'DashboardComponent',
  data() {
      return {
        auth: getAuth(),
        user: {},
        budget: {},
        transactions: [],
        transactionsSort: [],
        uid: null,
        balance: this.$store.state.balance
      }
  },
  created() {
    this.setUid();
    this.getUser(this.uid);
    this.getBudget(this.uid);
    this.getTransactions(this.uid);
    this.transactionsSort = this.transactions.sort(
        (objA, objB) => Number(objB.date) - Number(objA.date)
    );
    console.log(this.transactionsSort);
  },
  methods: {
    setBalance(){
      this.$store.commit('setBalance', this.budget.budget_amount);
    },
    setUid(){
      this.uid = this.auth.currentUser.uid;
    },
    async getUser(uid){
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()){
            this.user = docSnap.data();
        } else {
            console.log("No such document");
        }
    },
    async getBudget(uid){
        const docRef = doc(db, 'budgets', uid);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()){
          this.budget = docSnap.data();
          this.setBalance();
        } else {
          console.log("No such document");
        }
    },
    async getTransactions(uid){
      const userRef = doc(db, 'users', uid);
      const q = query(collection(db, 'transactions'), where("user", "==", userRef));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.transactions.push({id: doc.id, ...doc.data()});
      });
    }
  }
}
</script>

<style>

</style>