<template>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma-carousel@4.0.3/dist/css/bulma-carousel.min.css">
  <!-- Slideshow container -->
  <div class="slideshow-container">

    <!-- Full-width images with number and caption text -->
    <div class="mySlides fade">
      <div class="numbertext">1 / 3</div>
      <img src="../assets/images/slider/Banner11.png" style="width:100%;">

    </div>

    <div class="mySlides fade">
      <div class="numbertext">2 / 3</div>
      <img src="../assets/images/slider/Banner2.png" style="width:100%">

    </div>

    <div class="mySlides fade">
      <div class="numbertext">3 / 3</div>
      <img src="../assets/images/slider/Banner3.png" style="width:100%">
    </div>

    <!-- Next and previous buttons -->
    <a class="prev" @click="plusSlides(-1)">&#10094;</a>
    <a class="next" @click="plusSlides(1)">&#10095;</a>
  </div>
  <br>

  <!-- The dots/circles -->
  <div style="text-align:center">
    <span class="dot" @click="currentSlide(1)"></span>
    <span class="dot" @click="currentSlide(2)"></span>
    <span class="dot" @click="currentSlide(3)"></span>
  </div>

  <section class="section">
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column ">
          <h1 class="title-calc">Así se ven</h1>
          <h1 class="subtitle-calc">tus ahorros</h1>
          <h1 class="subtitle-calc-yellow">en <img class="img-miapp" src="../assets/LogoMIAPP1.png" alt=""></h1>
          <hr style="background-color: #FFB704;">
          <p style="text-align: left; font-weight: 500; width: 100%;">El interés anual que generan tus ahorros en MIAPP es del 18% como mínimo, o más. Esta gráfica fue diseñada con datos reales de los bancos más conocidos de tu comunidad.</p>
        </div>

        <div class="column">
          <img src="../assets/images/graficacomparacion.png" alt="">
        </div>
      </div>
    </div>
  </section>
  <!-- Fin de services section -->

  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column"></div>
        <div class="column">
          <h1 class="title" style="background-color: #FBB50A; color: #fff; font-size: 2.5rem; margin-top: 0;">MICalculadora</h1>
          <p style="color: #C28A00; font-weight: 500; font-size: 0.75rem;">Calcula cuántos intereses vas a generar basado en la cantidad que quieras ahorrar en MIApp (ahorros comunes).</p>
          <div class="field" style="margin-top: 40px;">
            <div class="select" style="display: block;">
              <select name="selectCurrency" id="" style="width: 100%; border-radius: 10px;" v-model="currency" >
                <option value="" disabled>Seleccione una moneda...</option>
                <option value="HNL">Lempiras</option>
                <option value="GTQ">Quetzales</option>
                <option value="CAD">Dolares canadienses</option>
              </select>
            </div>
          </div> <!--Fin div field-->

          <div class="field">
            <div class="control">
              <input type="number" class="input" style="border-radius: 10px;" placeholder="Escriba la cantidad" v-model="amountCurrency" />
            </div>
          </div><!--Fin div field-->

          <div class="field">
            <div class="control">
              <label for="" class="">Monto en USD</label>
              <input type="number" class="input" style="border-radius: 10px;" readonly placeholder="$" v-model="amountUsd">
            </div>
          </div> <!--Fin div field-->


          <button class="button is-rounded" @click="calculate" style="margin-bottom: 30px; background-color: #286DC2; color: white;">Calcular</button>
          <p style="text-align: left;">Interés anual mínimo: <span style="margin-left: 30px; color: #286DC2; font-weight: 600;">$ {{yearly}}</span></p>
          <p style="text-align: left;">Interés mensual mínimo: <span style="margin-left: 9px; color: #286DC2; font-weight: 600;">$ {{monthly}}</span></p>
          <p style="text-align: left;">Interés diario mínimo: <span style="margin-left: 30px; color: #286DC2; font-weight: 600;">$ {{daily}}</span></p>
          <hr style="background-color: #FFB704; height: 3px;">
          <p style="font-size: 1.3rem; font-weight: 600;">Recuerda</p>
          <p style="color: #8B8B8B; font-weight: 500; font-size: 0.9rem;"> Los intereses varían en un rango del <span style="color: #286DC2">0.05% al 5% diario.</span> Todos los días es un interés diferente. Los datos proporcionados son basados en el mínimo,
        así que siempre vas a generar más de lo que esta calculadora te muestra. Aún el mínimo es 5 veces más que cualquier banco que conozcas.</p>
        </div>
        <div class="column"></div>
        
      </div>
    </div>
  </section>
  <!-- Fin de MICalculadora section -->
  <div class="columns">
    <div class="column is-1"></div>
    <div class="column">
      <img src="../assets/images/ISOTIPO.png" alt="">
      <p class="title text-personalizado" >Sobre nosotros</p>
      <p class="subtitle-feature"> <strong>MIApp</strong> es tu mejor opción financiera. El objetivo principal de <strong>MIApp</strong> es facilitar tus transacciones diarias, eliminando las largas filas...</p>
      <router-link to="/about" class="button is-link is-outlined">Saber más</router-link>
    </div>
    <div class="column is-1"></div>
    <div class="column">
      <img src="../assets/images/iconocrecimiento.png" alt="">
      <p class="title text-personalizado">Servicios</p>
      <p class="subtitle-feature">Ofrecemos soluciones de superación para PYMES y personas regulares, esto lo hacemos a través de varios servicios como ser: POS accesible, mas intereses por tus ahorros, atención personalizada...</p>
      <router-link to="/products" class="button is-link is-outlined">Saber más</router-link>
    </div>
    <div class="column is-1"></div>
  </div>

  <div class="columns">
    <div class="column is-1"></div>
    <div class="column">
      <img src="../assets/images/iconoservper.png" alt="">
      <p class="title text-personalizado">Seguridad</p>
      <p class="subtitle-feature">El temor de cualquier cosa en el mundo digital existe. Para calmar los aires te ofrecemos la última tecnología de visa y máster 3D- Secure...</p>
    </div>
    <div class="column is-1"></div>
    <div class="column">
      <img src="../assets/images/iconotransacciones.png" alt="">
      <p class="title text-personalizado">
        Otros
      </p>
      <p class="subtitle-feature"><strong>Modern Investments</strong> es una sociedad con varios negocios importantes, que se separan de la siguiente forma: <strong>MIApp, MIEnvíos, MIEntretenimientos.</strong></p>
    </div>
    <div class="column is-1"></div>
  </div>

  <!-- <section class="section" id="servicios">
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-parent is-4">
          <article class="tile is-child notification is-info">
            <p class="title">AHORROS</p>
            <p>A plazo fijo a una tasa del 13% anual y ahorros comunes a una tasa del 0.4% mensual.</p>
          </article>
        </div>
        <div class="tile is-parent is-4">
          <article class="tile is-child notification is-warning">
            <p class="title">FONDOS</p>
            <p>Fondo de inversión, Educación, servicio funebres, servicios Médicos básicos y pastillas,</p>
          </article>
        </div>
        <div class="tile is-parent is-4">
          <article class="tile is-child notification is-info">
            <p class="title">REMESAS</p>
            <p>Recibimos y enviamos remesas a través de la última tecnología P2P.</p>
          </article>
        </div>
      </div>
    </div>
  </section> -->
  <!-- Fin de services section -->
  <!-- Fin de download apps section -->

<!--  <section class="section">
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column">
          <img src="../assets/images/graficoservicios.jpg" alt="">
        </div>
        <div class="column ">
          <h1 class="title">Servicios</h1>
          <p class="subtitle">Una empresa de inversiones digitales y físicas, que su finalidad es multiplicar los bienes de sus afiliados, a través de
            servicios como: ahorros a plazo fijo, ahorros comunes, fondo de pensión y universitarios, beneficios...</p>
          <router-link to="/products" class="button is-warning">Saber más</router-link>
        </div>
      </div>
    </div>
  </section>-->
  <!-- Fin de services section -->

<!--  <section class="section">
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column">
          <h1 class="title">Nuestro equipo</h1>
          <p class="subtitle">Nuestro equipo de soporte técnico y atención al cliente están disponibles todos los días
            para brindarte una atención de primer nivel. Puedes escribirnos vía correo electrónico y WhatsApp</p>
        </div>
        <div class="column">
          <img src="../assets/images/team.png" alt="">
        </div>
      </div>
    </div>
  </section>-->
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return {
      slideIndex: 1,
      yearly: 0,
      monthly: 0,
      daily: 0,
      amountUsd: null,
      currency: '',
      amountCurrency: null,
      toRateUsd: null
    }
  },
  mounted() {
    this.showSlides(this.slideIndex);
  },
  methods: {
    async calculate(){
      await this.getDataCurrency();
      this.amountUsd = (this.amountCurrency / this.toRateUsd).toFixed(2);
      this.daily = (this.amountUsd * 0.0005).toFixed(2);
      this.monthly = (this.daily * 30).toFixed(2);
      this.yearly = (this.monthly * 12).toFixed(2);
    },
    async getDataCurrency(){
      const currencies = await fetch('https://open.er-api.com/v6/latest/USD')
          .then(response => {
            return response.json();
          });
      this.toRateUsd = currencies.rates[this.currency];
      console.log("Rate", this.toRateUsd);

    },
    plusSlides(n){
      this.showSlides(this.slideIndex += n);
    },
    currentSlide(n){
      this.showSlides(this.slideIndex = n);
    },
    showSlides(n){
      let i;
      let slides = document.getElementsByClassName("mySlides");
      let dots = document.getElementsByClassName("dot");
      if (n > slides.length) {this.slideIndex = 1}
      if (n < 1) {this.slideIndex = slides.length}
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }
      slides[this.slideIndex-1].style.display = "block";
      dots[this.slideIndex-1].className += " active";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.container{
  background-color: transparent;
}
/* Slideshow container */
.slideshow-container {
  max-width: 100%;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.prev{
  left: 0;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

.text-personalizado{
  color: #286DC2;
  font-weight: 800;
  font-size: 1.8rem;
  line-height: 1.125;
  margin-top: 0;
}

.title-calc{
  color: #286DC2;
  font-weight: 800;
  font-size: 3rem;
  text-align: left;
  line-height: normal;
  margin-top: 0;
}

.subtitle-calc{
  font-size: 3rem; 
  color: #286DC2; 
  margin: 0; 
  font-weight: 500; 
  text-align: left; 
  line-height: normal;
}

.subtitle-calc-yellow{
  text-align: left; 
  font-size: 3rem; 
  color: #286DC2; 
  font-weight: 500; 
  height: 50; 
  line-height: normal;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

.img-miapp{
  height: auto;
  max-width: 100%;
}

.subtitle-feature{
  font-size: 1.3rem;
}

@media screen and (max-width: 768px) {
  select{
    height: 2.2em;
    font-size: 0.8rem;
  }
  .title-calc, .subtitle-calc, .subtitle-calc-yellow{
    font-size: 1.5rem;
  }

  .img-miapp{
    width: 170px;
    height: 30px;
  }

  .text-personalizado{
    font-size: 1.5rem;
  }

  .subtitle-feature{
  font-size: 1.1rem;
}

}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
</style>
