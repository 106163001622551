<template>
<section class="section">
    <div class="container">
        <h1 class="title">Transfer funds</h1>
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <div class="tile is-child notification is-warning">
                    <p class="title">Disponible</p>
                    <p class="subtitle">$ {{balance}}</p>
                </div>
            </div>
        </div>
        <div class="field">
            <p class="control is-expanded">
                <input class="input" type="email" placeholder="Correo de destino">
            </p>
        </div>
        <div class="field">
            <p class="control is-expanded">
                <input class="input" type="number" placeholder="Monto que desea enviar">
            </p>
        </div>
        <div class="field">
            <p class="control is-expanded">
                <input class="input" type="text" placeholder="Descripción">
            </p>
        </div>
        <div class="field">
            <div class="columns is-centered">
                <div class="column is-4">
                    <button class="button is-fullwidth is-primary">Transfer</button>
                </div>
                <div class="column is-4">
                    <router-link to="/dashboard" class="button is-fullwidth is-danger">Cancel</router-link>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
  name: 'TransferComponent',
  data(){
    return {
      balance: this.$store.state.balance
    }
  }
}
</script>

<style></style>