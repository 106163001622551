<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300&display=swap" rel="stylesheet">
  <NavMenu />
  <FooterComponent />
</template>

<script>
import NavMenu from './components/Nav.vue'
import FooterComponent from './components/Footer'

export default {
  name: 'App',
  components: {
    NavMenu,
    FooterComponent
  }
}
</script>

<style>

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 50px;
}


</style>
