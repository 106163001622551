<template>
    <nav class="navbar is-dark is-fixed-top" role="navigation" aria-label="dropdown navigation" >
        <div class="navbar-brand">
        <!-- navbar items, navbar burger... -->
        <router-link to="/" class="navbar-item">
            <img src="../assets/images/Imagotipo.png" />
        </router-link>
          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" @click="isOpen = !isOpen" data-target="menu-principal" v-bind:class="{'is-active': isOpen}">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="navbar-menu" id="menu-principal" v-bind:class="{'is-active': isOpen}">
          <div class="navbar-start"></div>
          <div class="navbar-end">
            <router-link to="/" class="navbar-item">Inicio</router-link>
            <router-link to="/about" class="navbar-item">Nosotros</router-link>
            <router-link to="/products" class="navbar-item">Servicios</router-link>
            <router-link to="/contact" class="navbar-item">Contactanos</router-link>
          </div>
         <!-- navbar start, navbar end -->
          <!--<router-link v-if="isLoggedIn" to="/dashboard" class="navbar-item">Dashboard</router-link>
          <div class="navbar-start" v-if="!isLoggedIn">
            <router-link to="/" class="navbar-item">Inicio</router-link>
            <router-link to="/about" class="navbar-item">Nosotros</router-link>
            <router-link to="/products" class="navbar-item">Servicios</router-link>
            <router-link to="/contact" class="navbar-item">Contactanos</router-link>
            <router-link to="/mipago" class="navbar-item">MIPago</router-link>
            <router-link to="/miremesas" class="navbar-item">MIRemesa</router-link>
          </div>

         <div class="navbar-end" v-if="isLoggedIn">
            <router-link to="/login" class="navbar-item" >Login</router-link>
            <router-link to="/register" class="navbar-item" >Register</router-link>
         </div>
         <div class="navbar-end" v-if="isLoggedIn">
            <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">Settings</a>
                <div class="navbar-dropdown">
                  <router-link to="/my-profile" class="navbar-item">My profile</router-link>
                  <a class="navbar-item" @click="handleSingOut">Logout</a>
                  <a class="navbar-item" @click="changeLanguage">ES-EN</a>
                  <router-link to="/mywithdrawals" class="navbar-item">My withdrawals</router-link>
                </div>
            </div>
            
         </div> Fin de navbar end-->
        </div> <!-- Fin de navbar Menu -->
    </nav>

<router-view></router-view>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from '@firebase/auth'
export default{
    name: 'NavMenu',
    data() {
        return {
          isLoggedIn: this.$store.getters.loggedIn,
          auth: getAuth(),
          isOpen: false
        }
    },
    methods: {
        handleSingOut(){
            signOut(this.auth).then(() => {
                this.$router.push('/');
            });
        },
      changeLanguage(){
          alert("Cambiando idioma...");
      }
    },
}
</script>

<style scoped>
.navbar.is-dark
{
  background-color: #2F3032;
}
</style>