<template>
<div class="background-body">
    <img src="../assets/modertextwhite.png" style="margin-top: 10px;" alt="" />
    <div class="container">
        <div class="columns">
            <div class="column"></div>
            <div class="column is-8">
                <div class="field">
                  <label class="label" for="">Email</label>
                  <div class="control">
                      <input type="text" class="input" placeholder="User" v-model="email" />
                  </div>
                </div>

                <div class="field">
                    <label for="" class="label">Password</label>
                    <div class="control">
                        <input type="password" class="input" placeholder="Password" v-model="password" />
                    </div>
                </div>
                <div class="field">
                    <label for="" class="label">Confirm password</label>
                    <div class="control">
                        <input type="password" class="input" v-model="confirmPass" placeholder="Confirm password" />
                    </div>
                </div>
                <button class="button is-dark" @click="register">Register</button>
            </div>
            <div class="column"></div>
        </div>
    </div>
</div>

</template>

<script>
import {getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { db } from "@/firebaseConfig";
import {doc, getDoc, setDoc, updateDoc} from 'firebase/firestore';

export default {
    name: 'RegisterComponent',
    data() {
      return {
        email: "",
        password: "",
        confirmPass: "",
        accountNumber: null
      }
    },
    methods: {
      register() {
        if(this.password != this.confirmPass)
        {
          this.$swal.fire({icon: 'error', title: 'Oops!', text: 'Something went wrong! Both passwords must be the same.'});
          return;
        }
        const auth = getAuth();
        this.getCorrelativeAccount();
        createUserWithEmailAndPassword(auth, this.email, this.password).then((data) => {
          this.createUser(auth);
          this.createBudget(auth.currentUser.uid);
          this.$router.push('/dashboard');
        }).catch((error) => {
            console.log(error.code);
        })
      },
      async getCorrelativeAccount(){
        const docRef = doc(db, 'sequences/account_sequence');
        const docSnap = await getDoc(docRef);
        this.accountNumber = docSnap.data().correlative;
      },
      async createUser(info){
         const newUserRef = doc(db, "users", info.currentUser.uid);
         const res = await setDoc(newUserRef, {
          acceptedTerms: true,
          created_time: new Date(),
          email: info.currentUser.email,
          isMIAccount: true,
          uid: info.currentUser.uid,
          verified: false,
          display_name: "Default D.",
          photo_id_url: "",
          photo_url: "",
          age: 0
         });
      },
        async createBudget(uid){
            const newBudgetRef = doc(db, "budgets", uid);
            console.log(this.accountNumber);
            const ref = await setDoc(newBudgetRef, {
                accumulated_amount: 0.00,
                budget_amount: 0.00,
                budget_created: new Date(),
                budget_description: "Cuenta principal",
                budget_name: 8000000 + this.accountNumber,
                profits: 0.00,
                reserved_amount: 0.00,
                user: "/users/" + uid
            });
            const sequenceRef = doc(db, 'sequences/account_sequence');
            const res = await updateDoc(sequenceRef,{correlative: this.accountNumber + 1});
        }
    },
}
</script>
<style></style>